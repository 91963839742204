import React from "react"
import { Trans, useTranslation } from "react-i18next"

export default function Plancard({
  price,
  surveys,
  answers,
  title,
  exportCSV,
  logo,
  notifications,
  valuation,
  answers_per,
  plan,
}) {
  const { t } = useTranslation()

  return (
    <div
      className="border-1 border border-light rounded shadow col-12 g-0 hvr-scale"
      style={{ maxWidth: "300px" }}
    >
      <div className="bg-dark text-light p-3 rounded ">
        <h4
          className="py-2 border-2 border border-light
         rounded text-center m-0 bg-warning text-dark"
        >
          {title}
        </h4>
        <h5 className="text-center m-0 pt-4">
          {price === "-" ? (
            t("Contact Us")
          ) : (
            <>
              {price} {"€ / "}
              {plan ? <Trans>Year</Trans> : <Trans>Month</Trans>}
            </>
          )}
        </h5>
      </div>
      <div className="text-center">
        <div className="list-group-item text-center">
          {surveys === "unlimited" ? <>∞</> : surveys} <Trans>surveys</Trans>
        </div>
        <div className="list-group-item text-center">
          {answers === "unlimited" ? <>∞</> : answers} <Trans>answers</Trans>
          {answers_per !== "unlimited" && (
            <>
              {" "}
              / <Trans>{answers_per}</Trans>
            </>
          )}
        </div>
        <div className="list-group-item text-center">
          <Trans>Result statistics</Trans>
        </div>
        <div className="list-group-item text-center">
          <Trans>Theme styling</Trans>
        </div>
        <div className="list-group-item text-center">
          <Trans>Support via</Trans> e-mail
        </div>
        <div className="list-group-item text-center">
          {logo ? (
            <Trans>Use your logo</Trans>
          ) : (
            <s style={{ opacity: "0", cursor: "default" }}>
              <Trans>Use your logo</Trans>
            </s>
          )}
        </div>
        <div className="list-group-item text-center">
          {exportCSV ? (
            <>
              Excel / CSV <Trans>export</Trans>
            </>
          ) : (
            <s style={{ opacity: "0", cursor: "default" }}>
              Excel / CSV <Trans>export</Trans>
            </s>
          )}
        </div>
        <div className="list-group-item text-center">
          {notifications ? (
            <Trans>E-mail Notifications</Trans>
          ) : (
            <s style={{ opacity: "0", cursor: "default" }}>
              <Trans>E-mail Notifications</Trans>
            </s>
          )}
        </div>
        <div className="list-group-item text-center">
          {valuation ? (
            <Trans>Evaluation</Trans>
          ) : (
            <s style={{ opacity: "0", cursor: "default" }}>
              <Trans>Evaluation</Trans>
            </s>
          )}
        </div>
      </div>
    </div>
  )
}
