import React from "react"
import Plancard from "../components/plancard"
import { Trans } from "gatsby-plugin-react-i18next"

const Pricing = ({ modules }) => {
  const [plan, setPlan] = React.useState(false)
  const isSSR = typeof window === "undefined"
  const BootstrapSwitchButton = React.lazy(() =>
    import("bootstrap-switch-button-react")
  )

  function numberWithDot(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
  }

  return (
    <>
      <div className="text-center p-5 w-100 " style={{ maxWidth: "300px" }}>
        {!isSSR && (
          <React.Suspense fallback={<div />}>
            <BootstrapSwitchButton
              checked={plan}
              onlabel={<Trans>Yearly</Trans>}
              onstyle="warning"
              style="w-100 border-light shadow "
              offlabel={<Trans>Monthly</Trans>}
              offstyle="dark"
              onChange={() => setPlan(!plan)}
            />
          </React.Suspense>
        )}
      </div>

      <div
        className="row w-100 justify-content-evenly
         gap-5 align-items-stretch align-content-center"
      >
        {Object.entries(modules).map((val, i) => (
          <React.Fragment key={i}>
            <Plancard
              title={val[1].name}
              price={plan ? val[1].price_year : val[1].price_month}
              surveys={numberWithDot(val[1].surveys)}
              notifications={val[1].notifications}
              valuation={val[1].valuation}
              logo={val[1].logo}
              exportCSV={val[1].export}
              answers={numberWithDot(val[1].answers)}
              answers_per={val[1].answers_per}
              plan={plan}
            />
          </React.Fragment>
        ))}
      </div>

      <a
        className="btn btn-lg btn-success m-5 px-5 py-3 shadow border-light"
        href="https://app.uhappy.gr/module"
      >
        <b>
          <Trans>Get Now</Trans>
        </b>
      </a>
    </>
  )
}

export default Pricing
