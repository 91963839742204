import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { database } from "../firebase"
import { onValue, ref } from "firebase/database"
import Spinner from "../components/spinner"
import Pricing from "../components/pricing"
import { graphql } from "gatsby"
import svgbg from "../images/animhorizbackB.svg"
import { useTranslation } from "react-i18next"

const Plans = () => {
  const [modules, setModules] = React.useState({})
  const [ready, setReady] = React.useState(false)

  const { t } = useTranslation()

  const fetchModules = async () => {
    onValue(
      ref(database, "modules"),
      snapshot => {
        setModules(snapshot.val())
        setReady(true)
      },
      {
        onlyOnce: true,
      }
    )
  }

  React.useEffect(() => {
    fetchModules()
  }, [])

  return (
    <Layout>
      <Seo title={t("Plans & Pricing")} />
      <div
        className="bg-warning p-0 p-md-3 p-lg-5 d-flex flex-column
           align-items-center justify-content-center"
        style={{ background: `url(${svgbg}) no-repeat fixed center center ` }}
      >
        {ready ? (
          <Pricing modules={modules} />
        ) : (
          <div
            className="w-100 text-center justify-content-center
        d-flex p-5 my-5 align-items-center"
            style={{ minHeight: "50vh" }}
          >
            <Spinner />
          </div>
        )}
      </div>
    </Layout>
  )
}

export default Plans
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
